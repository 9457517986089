@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
html {
  background-color: #222222;
}

body {
  font-family: 'Montserrat', sans-serif;
  --tw-bg-opacity: 1;
  background-color: rgba(34, 34, 34, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-text-opacity));
}

body.dialog-open {
  overflow: hidden;
}

h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 400;
}

code {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(34, 34, 34, var(--tw-bg-opacity));
  padding: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace;
}

input {
  background: transparent;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(233, 234, 233, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-text-opacity));
}

input::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

input:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

input::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

input:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

input:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

input:focus::placeholder{
  --tw-placeholder-opacity: 0.5;
}

input:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

select {
  background: transparent;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(233, 234, 233, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-text-opacity));
}

select::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

select:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

select::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-placeholder-opacity));
}

select:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

select:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5;
}

select:focus::placeholder{
  --tw-placeholder-opacity: 0.5;
}

select:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-text-opacity));
}

.btn-primary {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(254, 192, 4, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(254, 192, 4, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(254, 192, 4, var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 1.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.btn-primary:hover {
  --tw-bg-opacity: 0.1;
}

.btn-primary:disabled {
  cursor: not-allowed;
  background-color: #FEC00440;
  opacity: 0.5;
}

.btn-secondary {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(254, 192, 4, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(254, 192, 4, var(--tw-text-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.btn-secondary:hover {
  --tw-text-opacity: 0.75;
}

.btn-secondary:disabled {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(254, 192, 4, var(--tw-text-opacity));
  opacity: 0.5;
}

.btn-toggler__wrapper {
  display: flex;
  height: 1.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  --tw-bg-opacity: 1;
  background-color: rgba(233, 234, 233, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}

.btn-toggler__wrapper button+button {
  margin-left: 0.5rem;
}

.btn-toggler {
  border-radius: 0.25rem;
  --tw-bg-opacity: 0.1;
  padding: 0px;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-toggler:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 234, 233, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.1;
}

.btn-toggler__open {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: 180deg;
  --tw-bg-opacity: 1;
  background-color: rgba(233, 234, 233, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(34, 34, 34, var(--tw-text-opacity));
}

.btn-toggler__open:hover {
  --tw-bg-opacity: 0.1;
  --tw-text-opacity: 1;
  color: rgba(233, 234, 233, var(--tw-text-opacity));
}

.dialog-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: auto;
}

.dialog--backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.6;
}

.dialog--container {
  overflow-y: auto;
}

.dialog {
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(233, 234, 233, var(--tw-border-opacity));
  --tw-border-opacity: 0.5;
  --tw-bg-opacity: 1;
  background-color: rgba(34, 34, 34, var(--tw-bg-opacity));
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dialog--container__mini .dialog {
  max-width: 400px;
}

.dialog--container__confirmation .dialog {
  max-width: 470px;
  padding: 1.5rem;
  margin-left: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

svg {
  fill: currentColor;
}

svg:focus {
  outline: none;
}

.checkbox-group {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-group > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.checkbox-input {
  height: 1rem;
  width: 1rem;
}

.checkbox-label {
  flex: 1 1 0%;
  flex-grow: 1;
}

/* purgecss start ignore */

/* Start purging... */

/* Stop purging. */

/* Start purging... */

/* Stop purging. */

/* purgecss end ignore */

