@layer components {
    .btn-primary {
        @apply bg-yellow border border-yellow bg-opacity-25 font-semibold text-yellow;
        @apply px-4 py-2;
        @apply rounded-3xl;
        @apply transition duration-300;
    }

    .btn-primary:hover {
        @apply bg-opacity-10;
    }

    .btn-primary:disabled {
        @apply bg-yellow-light opacity-50 cursor-not-allowed;
    }

    .btn-secondary {
        @apply text-yellow border-b border-yellow;
        @apply px-6 py-2;
        @apply transition duration-300;
    }

    .btn-secondary:hover {
        @apply text-opacity-75;
    }

    .btn-secondary:disabled {
        @apply text-yellow opacity-50 cursor-not-allowed;
    }

    .btn-toggler__wrapper {
        @apply h-7 py-2 px-1 rounded flex justify-center items-center;
        @apply transition duration-300;
        @apply bg-light bg-opacity-10;
    }

    .btn-toggler__wrapper button+button {
        @apply ml-2;
    }

    .btn-toggler {
        @apply rounded bg-opacity-10 p-0 transition;
    }

    .btn-toggler:hover {
        @apply bg-opacity-10 bg-light;
    }

    .btn-toggler__open {
        @apply bg-light text-dark transform rotate-180;
    }

    .btn-toggler__open:hover {
        @apply bg-opacity-10 text-light;
    }

    .btn-delete {
        @apply h-5 rounded w-5 bg-opacity-10 bg-center bg-no-repeat p-2;
        background-image: url("../../assets/icon-delete.svg");
    }

    .btn-delete:hover {
        @apply bg-light bg-opacity-10;
    }

    .dialog-wrapper {
        @apply fixed inset-0;
        overflow: auto;
    }

    .dialog--backdrop {
        @apply fixed inset-0;
        @apply bg-black bg-opacity-60;
    }

    .dialog--container {
        @apply overflow-y-auto;
    }

    .dialog {
        @apply bg-dark my-16 px-12 py-8 rounded-lg border border-light border-opacity-50;
    }

    .dialog--container__mini .dialog {
        max-width: 400px;
    }

    .dialog--container__confirmation .dialog {
        max-width: 470px;
        padding: 1.5rem;
        margin-left: auto;
    }

    .form-group {
        @apply mb-6;
    }

    .form-group label {
        @apply block text-sm mb-2;
    }

    svg,
    use {
        fill: currentColor;
    }

    svg:focus {
        outline: none;
    }

    .checkbox-group {
        @apply flex flex-row space-x-4 justify-start items-center cursor-pointer;
    }

    .checkbox-input {
        @apply w-4 h-4;
    }

    .checkbox-label {
        @apply flex-1 flex-grow;
    }
}
